import { useEffect, useState } from "react";
import useFormulario from "../../../hooks/useFormulario";
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Grid, IconButton, Stack, Step, StepLabel, Stepper, Typography } from "@mui/material";
import { CardView } from "./CardView";
import { InputChoice } from "../InpultChoice";
import EditIcon from '@mui/icons-material/Edit';
import moment from "moment";
import { extrairData } from "../../../utils/extrairData";
import FormList from "antd/es/form/FormList";


export function ViewForm({ path, data, disabled, openEditName, setFieldValues = false, setDialogSettings, editarRespostas }) {
    const [activeStep, setActiveStep] = useState(0);
    const {
        isLoading,
        saveEndereceAndReclamada,
        saveEndereceAndReclamante,
        getCamposObrigatorios,
        getAllCampos,
        getvalidationSchema,
        verificarErroDoPasso,
        getIsLocal,
        getSteps,
        sendForm
    } = useFormulario()

    const initialValues = getAllCampos(data)
    const camposObrigatorios = getCamposObrigatorios(data)
    const validationSchema = getvalidationSchema(data)

    const formik = useFormik({
        initialValues,
        validationSchema: Yup.object(validationSchema),
        onSubmit: async (values) => {
            try {
                sendForm(values)
            } catch (error) {
                console.log(error)
            }
        },
    });

    const isLocal = getIsLocal(formik, data)
    const steps = getSteps(isLocal)

    function handleBack() {
        setActiveStep(prevStep => prevStep - 1)
    }

    function handleSubmit() {
        formik.handleSubmit()
    }

    async function handleNext(nome) {
        setActiveStep(prevStep => prevStep + 1)
    }


    useEffect(() => {
        if (setFieldValues) {
            Object.keys(setFieldValues).forEach(key => {
                const value = setFieldValues[key];
                if (moment(value, moment.ISO_8601, true).isValid()) {
                    formik.setFieldValue(key, extrairData(value))
                } else {
                    formik.setFieldValue(key, value)
                }
            });
        }

    }, [setFieldValues]);

    const dataFiltrada = isLocal.filter(item => {
        if (item.campo_formulario.length === 0) {
            return false;
        } else {
            const algumAtivado = item.campo_formulario.some(campo => campo.activated === true);
            return algumAtivado;
        }
    })

    return (
        <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-end"
            sx={{ mt: 1, px: 4 }}
            spacing={2}
        >
            <Grid item xs={3}>
                <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={1}
                >
                    <Typography sx={{ fontWeight: 500, fontSize: 21 }}>
                        {data[0]?.campo_formulario[0]?.formulario.descricao || "Novo"}
                    </Typography>
                    {Boolean(openEditName) && <IconButton onClick={() => openEditName(data[0]?.campo_formulario[0]?.formulario.descricao || "")} size="small" aria-label="editar">
                        <EditIcon sx={{ fontSize: 16 }} />
                    </IconButton>}
                </Stack>
            </Grid>
            <Grid item xs={9}>
                <Stepper activeStep={activeStep} alternativeLabel>
                    {steps.map((rotulo, indice) => (
                        <Step key={rotulo}>
                            <StepLabel error={verificarErroDoPasso(formik.errors, formik.touched, camposObrigatorios[`error${indice}`] || [])}>
                                {rotulo}
                            </StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </Grid>

            {dataFiltrada.map((grupos, index) => {
                const isFirstPage = index === 0;
                const isLastPage = index === dataFiltrada.length - 1;

                return (
                    index === activeStep && grupos.campo_formulario.length > 0 && (
                        <CardView
                            key={index}
                            handleNext={handleNext}
                            handleBack={handleBack}
                            handleSubmit={handleSubmit}
                            isFirstPage={isFirstPage}
                            isLastPage={isLastPage}
                            nome={grupos.nome}
                            isLoading={isLoading}
                        >
                            {grupos.campo_formulario.map((campos, indexCampos) => {
                                const activated = campos.activated
                                const value_depends = campos.campo.value_depends
                                const depends_on = campos.campo.depends_on

                                const showEditIcon = path === "PeticaoView" && editarRespostas || path === "FormularioEditar"

                                const handleOpenEdit = () => {

                                    if (path === "PeticaoView") {
                                        setDialogSettings({ campo: campos, grupo: grupos, editar: true, value: formik.values[campos.nome_id] })
                                        return
                                    }

                                    setDialogSettings({ campo: campos, grupo: grupos, editar: true })
                                }

                                if (activated) {
                                    if (depends_on) {
                                        const value_depends_formatted = value_depends === 'true' ? true
                                            : value_depends === 'false' ? false
                                                : value_depends;
                                        const depends_on_formatted = formik.values[depends_on] === 'true' ? true : formik.values[depends_on] === 'false' ? false : value_depends
                                        const showField = depends_on_formatted === value_depends_formatted
                                        if (showField) {
                                            return (
                                                <Grid key={indexCampos} item lg={campos.tamanho_label > 12 ? 12 : campos.tamanho_label}>
                                                    <InputChoice
                                                        showEditIcon={showEditIcon}
                                                        handleOpenEdit={handleOpenEdit}
                                                        disabled={disabled}
                                                        formik={formik}
                                                        campo={{ ...campos.campo, result_search: campos.result_search, nome_id: campos.nome_id }}
                                                    />
                                                </Grid>
                                            )
                                        }
                                    } else {
                                        return (
                                            <Grid key={indexCampos} item lg={campos.tamanho_label > 12 ? 12 : campos.tamanho_label}>
                                                <InputChoice
                                                    showEditIcon={showEditIcon}
                                                    handleOpenEdit={handleOpenEdit}
                                                    disabled={disabled}
                                                    formik={formik}
                                                    campo={{ ...campos.campo, result_search: campos.result_search, nome_id: campos.nome_id }}
                                                />
                                            </Grid>
                                        )
                                    }
                                }
                            })}
                        </CardView>
                    )
                )

            })}

        </Grid>
    )
}